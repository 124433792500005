.side-menu {
    border-radius: var(--border-radius-23);
    background-color: var(--greyscale-color);
    display: flex;
    flex-direction: column;
}

.side-menu .side-menu-header {
    display: flex;
    align-items: center;
    height: 55px;
    padding: 0 30px;
    border-radius: var(--border-radius-23) var(--border-radius-23) 0 0;
    background-color: var(--accent-color);
    font-size: 20px;
    color: #fff;
}

.side-menu .side-menu-body {
    padding: 20px;
    flex-grow: 1;
}
