.field-selection {
    width: 100%;
    display: flex;
    min-height: 300px;
    align-items: stretch;
}

.field-selection .fields-col {
    margin-right: 15px;
}

.field-selection .fields-col .cdp-id {
    font-weight: bold;
}

.field-selection .fields-col .fields-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.fields-col .fields-col-pills .rdt_TableCell {
    font-weight: bold;
}

.fields-col .fields-col-pills .rdt_Table,
.fields-col .fields-col-pills .rdt_ExpanderRow {
    background-color: transparent;
}

.selected-fields-col {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.selected-fields-col .selected-fields-card {
    flex: 1;
    background-color: var(--greyscale-color);
    border-radius: var(--border-radius-23);
    padding: 30px;
    transition: background-color 0.3s ease-in;
}

.selected-fields-col .selected-fields-card.hovered {
    background-color: rgba(var(--accent-color-rgb), 0.3);
}

.selected-fields-col .selected-fields-card .hint-container {
    padding-bottom: 10px;
    text-align: center;
}

.selected-fields-col .selected-fields-card .required-field-row {
    display: flex;
    align-items: center;
}

.selected-fields-col .selected-fields-card .required-field-row .required-field-pill {
    cursor: default;
    padding: 0;
    width: 275px;
    background-color: #fff;
    border-radius: var(--border-radius-23);
    border: 2px solid #d1d1d1;
    height: 40px;
}

.selected-fields-col
    .selected-fields-card
    .required-field-row
    .required-field-pill.required-pill-error {
    border-color: var(--error-color);
}

.selected-fields-col .selected-fields-card .required-field-row > p {
    min-width: 100px;
    margin-right: 10px;
}

.selected-fields-col .selected-fields-card .selections-row {
    display: flex;
}

.selected-fields-col .selected-fields-card .selections-row .selections-col {
    display: flex;
    flex-direction: column;
    justify-content: start;
    max-height: 630px;
    flex-wrap: wrap;
    gap: 5px;
}

.selected-fields-col .selected-fields-card .selections-row .clear-btn-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 5px;
}
