.segment .segment-primary-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 300px;
    margin: auto;
}

.segment .segment-primary-table .segment-primary-table-btn {
    margin-top: 10px;
    width: 100%;
}

.segment .segment-body {
    display: flex;
}

.segment .segment-body .segment-table-list {
    width: 265px;
    margin-right: 10px;
}

.segment .segment-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.segment select.segment-dropdown,
.segment input {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
}

.segment .segment-header {
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
}

.segment-header .segment-name {
    width: 250px;
    margin-right: 10px;
}

.segment-header .segment-action-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.segment-action-btns .action-btn-group {
    display: flex;
    align-items: center;
}

.segment-action-btns .action-btn-group button {
    margin-right: 10px;
}

.segment-action-btns .action-btn-group .consent-join-btn {
    margin-left: 10px;
    background-color: var(--accent-color);
}

.segment-action-btns .action-settings button {
    padding: 8px !important;
    margin-right: 2px !important;
}

.segment-action-btns .action-settings .results-count {
    font-weight: bold;
    font-size: 15px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 5px;
}

.segment-header .segment-header-menu {
    height: 40px;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-right: 10px;
}

.segment-header .segment-header-menu button {
    margin-right: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.segment-header .segment-header-name {
    width: 250px;
    padding-right: 10px;
}

.segment-header .segment-header-name p {
    text-align: left;
    margin: 0;
    padding: 5px;
}

.segment-header .segment-header-name input {
    width: 100%;
    padding: 5px;
}

.segment-header .segment-header-cancel {
    float: right;
}

.segment-header .segment-header-cancel button {
    height: 40px;
}

.segment .segment-builder-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.segment-builder-container .segment-builder-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.segment-builder-container .segment-builder {
    width: 100%;
}

.segment-builder-container .segment-builder.fixed {
    position: fixed;
    top: 10px;
}

.segment-builder .filter-group-card {
    position: relative;
    width: 100%;
    border-radius: var(--border-radius-23);
    display: flex;
    height: auto;
    overflow: hidden;
    min-height: 400px;
    max-height: 1000px;
    transition: all 0.5s ease-in-out;
}

.segment-builder .filter-group-card.collapsed {
    max-height: 150px;
    min-height: 150px;
}

.segment-builder .filter-group-card.include {
    margin-bottom: 10px;
}

.segment-builder .filter-group-card .drop-zone {
    width: 100%;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    padding: 10px;
}

.segment-builder .filter-group-card.include .drop-zone {
    background-color: rgba(var(--accent-color-rgb), 0.2);
}

.segment-builder .filter-group-card.exclude .drop-zone {
    background-color: rgba(var(--error-color-rgb), 0.2);
}

.segment-builder .filter-group-card .overlay {
    position: absolute;
    height: 100%;
    background-color: rgb(204, 204, 204);
    opacity: 0;
    left: 50px;
    right: 0;
    transition: opacity 0.5s ease-in-out, z-index 0s linear 0.51s;
    z-index: -1;
}

.segment-builder .filter-group-card.collapsed .overlay {
    opacity: 0.7;
    z-index: 1;
    cursor: pointer;
}

.segment-builder .filter-group-card .vertical-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    z-index: 1;
    cursor: pointer;
}

.segment-builder .filter-group-card.include .vertical-header {
    background-color: rgba(var(--accent-color-rgb), 0.5);
}

.segment-builder .filter-group-card.exclude .vertical-header {
    background-color: rgba(var(--error-color-rgb), 0.5);
}

.segment-builder .filter-group-card .vertical-header .vertical-header-title {
    transform: rotate(270deg);
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: bold;
}
